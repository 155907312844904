import { useMemo } from 'react';
import classNames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import { EmployersCompanyIndustry } from 'lux/models/employersCompany';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

interface IndustriesProps {
    industry: EmployersCompanyIndustry;
}

const Industry: React.FC<IndustriesProps> = ({ industry }) => {
    const { pathname, search } = useSelector(({ router }) => router.location);

    const href = useMemo(() => {
        const url = urlParser(`${pathname}/${industry.translit}${search}`);
        url.params.vacanciesRequired = null;
        return url.href;
    }, [industry.translit, pathname, search]);

    return (
        <div className={classNames(styles.industry, { [styles.subIndustry]: industry.parentId })}>
            <Link to={href}>{industry.title}</Link>
        </div>
    );
};

export default Industry;
